// Page Layout
// -----------------------------------------------------------------------------

.navbar.navbar-default {
  background-color: $light;
  border: none;

  .navbar-header {
    .navbar-brand {
      padding: 0px;
    }
  }

  .navbar-collapse {
    float: right;

    ul#main-menu {
      margin-top: 28px;
      > li {
        > a {
          color: $brand-gray;
          text-transform: uppercase;
          padding: 0 0 15px 0;
          margin: 0 15px;
        }
        &:hover,
        &.active {
          > a {
            background-color: $light;
          }
        }
      }
    }
  }
}

#header {
  .sticky {
    background-color: $light;
    height: 70px;

    .navbar {
      margin-bottom: 10px;
    }
  }

  #logo {
    height: 65px;
  }
}

/*
* SLIDESHOW
*/
.block-slideshow {
  .block__content {
    .view-slideshow {
        position: relative;

      .view-content {
        .views-field-field-slideshow-image {
          img {
            width: 100%;
            height: auto;
            margin: 0 auto;
            display: block;
          }
        }

        .views-field-field-slideshow-text {
          .field-content {
            position: absolute;
            right: 2%;
            bottom: 300px;
            color: $light;
            font-size: 5rem;
            font-family: $font-titles;
            font-weight: 600;
          }
        }
      }
    }
  }


  h4 {
    display: none;
  }

}

.page-header {
  margin: 0;
  padding: 0;
  border-bottom: 0;

  .title {
    display: none;
  }
}

/*
* CONTENT
*/

.page-title {
    color: #868686;
    border-color: #868686;
    font-family: "Merriweather",Helvetica,Arial,sans-serif;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid #868686;
    width: 40%;
    margin: 0 auto 1.25em auto;
    padding-bottom: 0.25em;
    text-transform: uppercase;
  }

.view-product {
  padding-top: 20px;

  .views-row {
    margin-bottom: 20px;
    text-align: center;

    .row-inner {
      margin: 0 10px;
      padding: 10px;
      background-color: $gray-lighter;

      .views-field-field-product-image {
        img {
          border-radius: 50%;
          width: 80%;
          height: auto;
          margin: 0 auto;
        }
      }

      .views-field-title {
        a {
          color: $red;
          font-size: 2.2rem;
          font-family: $font-titles;
          font-weight: 600;
          padding: 20px 0;
          height: 4em;
          line-height: normal;
          display: block;

          :hover {
            text-decoration: none;
          }
        }
      }

      .views-field-field-product-summary {
        p {
          font-size: 1.6rem;
          height: 8.5rem;
          border-top: 1px dotted black;
          border-bottom: 1px dotted black;
          padding-top: 10px;
        }
      }

      .views-field-field-product-variant {
        height: 2em;
      }

      .views-field-field-product-weight {

        ::after {
          content: " g";
        }
      }

      .views-field-commerce-price {
        color: $brand-primary;
        font-weight: 600;
        font-size: 2rem;
        padding-bottom: 8px;
      }

      .form-item-quantity {
        margin: 0 auto;
        clear: both;
        border-top: 1px dotted black;
        width: 100%;
        padding-top: 10px;

        .form-control {
          width: 40%;
          margin: 0 auto;
        }
      }
    }
  }

  .view-footer {
    text-align: center;
    clear: both;

    a {
      background: $brand-primary;
      border-radius: 10px;
      color: $red;
      font-size: 2rem;
      font-family: $font-titles;
      font-weight: 600;
      padding: 20px;
      height: 4em;
      text-transform: uppercase;
    }
  }
}

.block {
  .view-product {
    .views-row {
      .row-inner {
        .views-field-title {
          a {
            font-size: 2.0rem;
          }
        }

        .views-field-field-product-summary {
          p {
              height: 9rem;
          }
        }
      }
    }
  }
}

.front {
  .region-content {
    padding-top: 0px;
    padding-right: 0px;
  }

  .view-product {
    .views-row {
      margin-bottom: 60px;
    }
  }
}

.not-front {
    color: #000;

  .region-content {
    padding-top: 20px;
  }

  .views-field-field-product-variant {
    border-top: 1px dotted #000;
    border-bottom: 1px dotted #000;
    padding: 5px 0;
    margin-bottom: 5px;
  }

  .views-field-field-product-weight {
    width: 50%;
    float: left;
    font-family: $font-titles;
    font-weight: 600;
    padding-top: 3px;
    border-right: 1px dotted #000;
  }

  .views-field-commerce-price {
    width: 50%;
    float: right;
    font-family: $font-titles;
    font-weight: 600;
  }

  .form-submit {
    background-color: $red;
    border-color: #000;
    border-radius: 10px;
    color: $light;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 15px;
  }
}

.page-category,
.page-taxonomy {
  .region-content {
    padding-top: 0px;
  }
}

.node-type-product {
  .form-submit {
    background-color: $red;
    text-transform: uppercase;
    color: $light;
    font-weight: 600;
    display: block;
    margin: 0 auto;
  }

  .group-product-left {
  }

  .group-product-right {
  }

  .group-product-bottom {
    margin-top: 5rem;

    img {
      width: 33.33%;
      height: auto;
      float: left;
      padding: 10px;
    }
  }
}

.content {
  h1, h2, h3, h4 {
    font-family: $font-titles;
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid $brand-gray;
    text-transform: uppercase;
  }

  h2 {
    width: 58%;
    margin: 0 auto 1.25em auto;
    padding-bottom: 0.25em;
  }

  .entity-paragraphs-item {
    padding: 0 0 2.5em 0;
  }

  .paragraphs-item-text {
    background-color: $brand-primary;
    color: $light;

    h2 {
      color: $red;
      border-color: $red;
      padding-top: 80px;
    }

    .field-name-field-text-body {
      text-align: center;
      font-size: 1.8rem;

      a {
          color: $light;
        }
    }
  }

  .paragraphs-item-view {
    color: $brand-gray;

    h2 {
      color: $brand-gray;
      border-color: $brand-gray;
      padding-top: 80px;
    }
  }


}

.field-name-field-product-weight {
  .field-items {
    ::after {
      content: " g";
    }
  }
}

.region-sidebar {
    color: $brand-gray;
    padding-left: 20px;
    background-color: $gray-lighter;
    border: 1px solid $gray;
    border-radius: 5px;
    margin-top: 20px;

  .block {
    margin-bottom: 4rem;
  }

  .block-block {
    padding-top: 20px;
    padding-right: 20px;
  }

  .block__title {
    font-size: 2rem;
    font-family: $font-titles;
    font-weight: 600;
    padding-bottom: 1rem;
    margin-right: 50px;
    margin-bottom: 1.5rem;
  }

  ul {
    margin: 0 20px;
    padding-left: 0;

    li {
      border-bottom: 1.5px dotted $gray;
      list-style: none;

      a {
        color: $brand-gray;
        padding-left: 0;

        &.active {
          color: $gray-darker;
        }
      }

      &.first {
        border-top: 1.5px dotted $gray;
      }
    }
  }

  .view-id-commerce_cart_block {
    font-size: 1.1rem;

    .table {
      width: 95%;
      max_width: 95%;
    }

    .view-footer {
      padding-right: 20px;
      font-size: 1.5rem;

      .line-item-total {
      }

      ul {
        li {
          border: none;

           a {
             background-color: $brand-primary;
             border-radius: 10px;
             color: $light;
             padding: 8px;
             margin-top: 10px;
             display: inline-block;
            }
        }
      }
    }
  }

  .block--informace {
    .block__content {
      ul {
        li {
          a {
            display: block;
            padding: 10px 15px;
            }

          &.first {
            border-top: 1.5px dotted $gray;
          }
        }
      }
    }
  }
}


.region-content {
 /* padding-top: 20px;*/
  padding-right: 20px;

  .block-views {
    margin-top: 10rem;
    border-top: 1px dotted #000;
    margin-bottom: 50rem;
    clear: both;

    .block__title {
      font-size: 2.8rem;
      font-family: $font-titles;
      font-weight: 600;
      padding-bottom: 1.2rem;
      padding-top: 1.2rem;
      margin-right: 50px;
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }
}

.block-commerce-cart {
  padding-bottom: 4rem;

  .block__content {
    margin: 0 20px;

    .views-table {
      display: none;
    }

    .line-item-total {
      padding-bottom: 10px;
    }

    ul {
      li {
        border: none;

        a {
          border: none;
          color: $light;
          padding-right: 5px;
        }

        &.first {
          border: none;
        }

        &.line-item-summary-view-cart {
          background-color: $brand-primary;
          border-radius: 6px;
          padding: 5px;
          float: left;
          margin-right: 10px;
          color: $light;
        }

        &.line-item-summary-checkout {
          background-color: $brand-primary;
          border-radius: 6px;
          padding: 5px 7px 5px 5px;
          float: right;
          margin-right: 10px;
        }
      }
    }
  }
}

.pagination {
  display: block;
  text-align: center;
  margin: 40px auto;
  padding-top: 20px;
  clear: both;
}


.node-product {
  .commerce-product-extra-field {
    .commerce-product-title {
      color: $red;
      font-size: 2rem;
      font-family: $font-titles;
      font-weight: 600;
      text-transform: uppercase;
      padding-bottom: 1rem;
      margin-right: 50px;
      margin-bottom: 2rem;
      border-bottom: 1px dotted $gray;

      .commerce-product-title-label {
        display: none;
      }
    }
  }

  .field-name-field-product-body,
  .field-name-field-product-ingredients,
  .commerce-product-field-field-product-durability,
  .commerce-product-field-field-product-weight,
  .commerce-product-field-field-product-price {
    margin-bottom: 3rem;
  }

  .commerce-add-to-cart {
    .form-item-attributes-field-product-variant {
      width: 40%;
    }

    .form-item-quantity {
    }

    .form-submit {
        margin: 0;
        margin-top: 20px;
      }

  }

  .group-product-right {
  }

  .group-product-order {
    border: 1px dotted $gray;
    padding: 15px;
    margin-right: 20px;

    .commerce-product-field {
      padding-bottom: 20px;

      .field-label {
        font-family: $font-titles;
      }
    }
  }
}

.block-other-friends {
  margin-right: 15px;
}

.commerce-add-to-cart {
  padding-top:30px;

  .form-item-quantity {
    width: 40%;
  }
}

/*
* POST CONTENT
*/
#post-content {
  padding: 0 0 2.5em 0;
  color: $brand-gray;

  h2 {
    color: $brand-gray;
    border-color: $brand-gray;
    font-family: $font-titles;
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid $brand-gray;
    width: 40%;
    margin: 0 auto 1.25em auto;
    padding-bottom: 0.25em;
    text-transform: uppercase;
    padding-top: 80px;
  }

  .webform-client-form {
    .webform-component {
      padding: 0 15px;
    }

    .form-submit {
      margin: 0 auto;
      display: block;
      font-size: 1.6rem;
      text-transform: uppercase;
    }
  }
}

/*
* TERTIALY
*/
.tertialy {
  iframe {
    width: 100%;
  }
}

/*
* FOOTER
*/

#footer {
  margin: 0;
}

.footer {
  margin: 0;
  background-color: #000;
  color: $light;
}


/*
* RESPONSIVE MENU
*/


.responsive-menus {
  &.responsified {
    span.toggler {
      color: $red;
      background: $light;
      box-shadow: none;
      font-size: 2.7rem;
      padding-top: 25px;
      text-align: right;
    }
  }
}

/*
* RESPONSIVE
*/

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .content {
    .view-product {
      .views-row {
        .row-inner {
          .views-field-title {
            font-size: 3rem;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .content {
    .view-product {
      .views-row {
        .row-inner {
          .views-field-title {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .collapse {
    display: block;
  }

  .navbar.navbar-default {
    .navbar-collapse {
      float: right;
      width: 36%;
      border-top: none;
    }
  }

  .navbar-collapse {
    border-top: none;
  }

  .responsive-menus {
    &.responsified {
      span.toggler {
        padding-top: 0px;
        line-height: 0em;
      }
    }
  }

  .content {
    .view-product {
      .views-row {
        .row-inner {
          .views-field-field-product-image {
            img {
              width: 60%;
              margin: 0 auto;
              display: block;
            }
          }

          .views-field-title,
          .views-field-field-product-summary {
            text-align: center;
          }
         }
      }

      .view-footer {
        a {
          font-size: 1.8rem;
        }
      }
    }
  }

  #post-content {
    .block__content,
    .block__title {
      margin: 0 20px;
    }

    h2 {
    }

    .webform-client-form {
      .webform-component {
        padding: 0;
      }
    }
  }

  .region-content {
    padding-right: 10px;
    padding-left: 10px;

    .block-views {
      .block__title {
        margin-right: 0;
      }
    }
  }

  .node-product {
    .field-name-field-product-images {
      img {
        width: 95%;
        height: auto;
      }
    }

    .commerce-product-extra-field {
      .commerce-product-title {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .navbar.navbar-default {
    .navbar-collapse {
      width: 80% !important;
    }
  }
}

@media only screen and (max-width: 700px) {
  .content {
    h2 {
      width: 80%;
    }
  }

  #post-content {
    h2 {
      width: 80%;
    }
  }
}


@media (min-width: 1000px) and (max-width: 130px) {
  .block-slideshow {
    .block__content {
      .view-slideshow {
        .view-content {
          .views-field-field-slideshow-text {
            .field-content {
              /* left: 20%; */
              bottom: 200px;
              font-size: 4rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 700px) and (max-width: 999px) {
  .block-slideshow {
    .block__content {
      .view-slideshow {
        .view-content {
          .views-field-field-slideshow-text {
            .field-content {
              /* left: 20%; */
              bottom: 150px;
              font-size: 3rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 699px) {
  .block-slideshow {
    .block__content {
      .view-slideshow {
        .view-content {
          .views-field-field-slideshow-text {
            .field-content {
              /* left: 20%; */
              bottom: 125px;
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .block-slideshow {
    .block__content {
      .view-slideshow {
        .view-content {
          .views-field-field-slideshow-text {
            .field-content {
              /* left: 20%; */
              bottom: 100px;
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 380px) and (max-width: 449px) {
  .block-slideshow {
    .block__content {
      .view-slideshow {
        .view-content {
          .views-field-field-slideshow-text {
            .field-content {
              left: 2%;
              bottom: 50px;
              font-size: 5rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 350px) and (max-width: 379px) {
  .block-slideshow {
    .block__content {
      .view-slideshow {
        .view-content {
          .views-field-field-slideshow-text {
            .field-content {
              left: 2%;
              bottom: 50px;
              font-size: 3.5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 349px) {
  .block-slideshow {
    .block__content {
      .view-slideshow {
        .view-content {
          .views-field-field-slideshow-text {
            .field-content {
              left: 2%;
              bottom: 35px;
              font-size: 3rem;
            }
          }
        }
      }
    }
  }
}
